<template>
  <div class="ibox ">
    <div class="ibox-content mailbox-content">
      <div class="file-manager">
        <a
          class="btn btn-block btn-primary compose-mail"
          href="mail_compose.html"
        >
          Compose Mail
        </a>
        <div class="space-25"></div>
        <h5>Folders</h5>
        <ul class="folder-list m-b-md" style="padding: 0">
          <li v-for="(folder, index) in folders" :key="index">
            <router-link
              :to="`/mailbox/${$route.params.id}/folder/${folder.id}/`"
            >
              <i class="fa fa-inbox "></i>
              {{ folder.displayName }}
              <span class="label label-warning float-right">
                {{ folder.unreadItemCount }} / {{ folder.totalItemCount }}
              </span>
            </router-link>
          </li>
        </ul>
        <!-- <h5>Categories</h5>
        <ul class="category-list" style="padding: 0">
          <li>
            <a href="#">
              <i class="fa fa-circle text-navy"></i>
              Work
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-circle text-danger"></i>
              Documents
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-circle text-primary"></i>
              Social
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-circle text-info"></i>
              Advertising
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-circle text-warning"></i>
              Clients
            </a>
          </li>
        </ul> -->

        <!-- 
        <h5 class="tag-title">Labels</h5>
        <ul class="tag-list" style="padding: 0">
          <li>
            <a href="">
              <i class="fa fa-tag"></i>
              Family
            </a>
          </li>
          <li>
            <a href="">
              <i class="fa fa-tag"></i>
              Work
            </a>
          </li>
          <li>
            <a href="">
              <i class="fa fa-tag"></i>
              Home
            </a>
          </li>
          <li>
            <a href="">
              <i class="fa fa-tag"></i>
              Children
            </a>
          </li>
          <li>
            <a href="">
              <i class="fa fa-tag"></i>
              Holidays
            </a>
          </li>
          <li>
            <a href="">
              <i class="fa fa-tag"></i>
              Music
            </a>
          </li>
          <li>
            <a href="">
              <i class="fa fa-tag"></i>
              Photography
            </a>
          </li>
          <li>
            <a href="">
              <i class="fa fa-tag"></i>
              Film
            </a>
          </li>
        </ul> -->
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import MailService from "@/services/mail.service.js";
export default {
  name: "MailboxNav",
  data() {
    return {
      folders: {}
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      new MailService()
        .fetchAllFolders(this.$route.params.id)
        .then(resp => {
          console.log(resp);
          this.folders = resp.data.value;
          this.$emit("redy", true);
          console.log("nav_ready");
          // this.$router.push({
          //   path: `/mailbox/${this.$route.params.id}/folder/${this.folders[0].id}/`
          // });
        })
        .catch(err => {
          this.$toastr.error(err);
          console.log(err);
        });
    }
  }
};
</script>
