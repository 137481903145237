<template>
  <div class="row">
    <div class="col-lg-2 p-0">
      <Nav @redy="test" />
    </div>
    <div class="col-lg-10 p-0 animated fadeInRight">
      <template v-if="folder_redy">
        <router-view :key="$route.fullPath" />
      </template>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/mailbox/Nav.vue";

export default {
  name: "MailboxWrapper",
  components: {
    Nav
  },
  data() {
    return {
      folder_redy: false
    };
  },
  methods: {
    test(val) {
      console.log(val);
      this.folder_redy = val;
      this.$forceUpdate();
    }
  }
};
</script>
